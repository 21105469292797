/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import '~@angular/material/theming';

@include mat-core();
 $candy-app-primary: mat-palette($mat-blue, 900);        
 $candy-app-accent: mat-palette($mat-grey, 50);
 $candy-app-warn: mat-palette($mat-red, 700);
 $candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
 @include angular-material-theme($candy-app-theme);
